const NoticePrivacity = () => {
  return (
    <div id="seccion-terminos" className="principal">
      <h1 className="title" id="title">
        Aviso de Privacidad
      </h1>
      <p>
        De acuerdo a lo establecido en la Ley Federal de Protección de Datos
        Personales en Posesión de los Particulares, informamos a todos nuestros
        Usuarios (definidos como las personas que usan o acceden mediante
        cualquier dispositivo a nuestro Portal y que aceptan plenamente y sin
        reserva alguna todas y cada una de las disposiciones que en este Aviso
        de Privacidad se contienen), que el ingreso y uso de las páginas,
        aplicaciones y software del Portal <a href="/">www.vinneren.com.mx</a>,
        implica la aceptación y obliga al cumplimiento del mismo, por lo que en
        caso de no estar de acuerdo con el presente Aviso, deberán de abstenerse
        de acceder o utilizar nuestra página.
      </p>
      <p>
        Vinneren Consulting, S.A. de C.V. (“Vinneren”) es una empresa con
        domicilio en calle Vito Alessio Robles #82, colonia Florida, Alcaldía
        Álvaro Obregón, C.P. 01030, en la Ciudad de México, México, y es la
        responsable del uso, tratamiento y protección de tus datos personales, a
        través de altas medidas de seguridad técnicas y administrativas.
      </p>
      <p style={{marginBottom: '30px'}}>
        Ponemos a tu disposición el correo electrónico: legal@vinneren.com.mx
      </p>

      <h3 className="subtitle-h3">¿QUÉ DATOS PERSONALES RECOLECTAMOS?</h3>

      <p>
        Los datos personales que recopilamos al utilizar nuestra página y
        contratar nuestros servicios y productos son:
      </p>
      <ul style={{ marginBottom: '30px' }}>
        <li>Nombre</li>
        <li>Correo electrónico</li>
        <li>Teléfono</li>
        <li>Nombre de tu empresa</li>
      </ul>
      <p style={{marginBottom: '30px'}}>
        En caso de que solicitemos datos sensibles, solicitaremos tu
        consentimiento expreso.
      </p>

      <h3 className="subtitle-h3">
        ¿PARA QUÉ UTILIZAMOS LOS DATOS PERSONALES?
      </h3>

      <p>
        Todos los Datos Personales serán utilizados para redirigirte a un número
        general de contacto de Whatsapp con la finalidad de:
      </p>
      <ul style={{ marginBottom: '30px' }}>
        <li>Proveer información sobre nuestros productos y servicios</li>
        <li>Proveer los servicios y productos solicitados</li>
        <li>
          Elaborar estudios y programas necesarios para determinar hábitos de
          consumo
        </li>
        <li>
          Realizar evaluaciones periódicas de nuestros productos y servicios
        </li>
        <li>Informar sobre cambios en nuestros productos y servicios</li>
        <li>Evaluar la calidad de nuestro servicio</li>
        <li>Enviar promociones</li>
        <li>Emisión de facturas</li>
        <li>Diseño de páginas web y registro en plataformas de servicios</li>
        <li>Nombre</li>
      </ul>

      <h3 className="subtitle-h3">
        DERECHOS ARCO (ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN)
      </h3>

      <p>
        En caso de que desees ejercer los Derechos Arco, es necesario que
        presentes la solicitud a través del envío de un correo electrónico a la
        dirección <b>legal@vinneren.com.mx</b>, indicándonos el derecho que
        desees ejercitar, identificándote con tu nombre, domicilio, documento
        que acredite tu identidad y en su caso, los documentos que acrediten la
        representación legal del solicitante.
      </p>
      <p>
        Si alguno de los requisitos anteriormente señalados no se adjunta al
        correo, te será notificado posteriormente a la solicitud, pidiéndote los
        datos o documentos faltantes y tendrás un plazo de 5 días hábiles a
        partir de esta notificación para proporcionar dicha información o
        documentación, de lo contrario, tu solicitud se tendrá por no realizada.
      </p>
      <p style={{marginBottom: '30px'}}>
        En caso de querer revocar el consentimiento que hayas dado para el
        tratamiento de tus datos personales, es importante que tengas en cuenta
        que, en algunos casos, esta revocación podría implicar el que no podamos
        seguir prestando los servicios que se solicitaron.
      </p>

      <h3 className="subtitle-h3">TRANSFERENCIA DE DATOS</h3>

      <p style={{marginBottom: '30px'}}>
        “Vinneren” podrá transferir ciertos datos a terceros que en virtud de
        los servicios y productos que ofrecemos necesiten conocerlos, o a las
        autoridades competentes en términos de la legislación aplicable y se
        realizará conforme a lo establecido en la Ley Federal de Protección de
        Datos Personales en Posesión de los Particulares.
      </p>

      <h3 className="subtitle-h3">MODIFICACIONES AL AVISO DE PRIVACIDAD</h3>

      <p>
        Este Aviso podrá sufrir modificaciones, cambios o actualizaciones por
        ajustes en la legislación, requerimientos, cambios en los productos y
        servicios que ofrecemos o necesidades de “Vinneren”, entre otras causas.
      </p>
      <p style={{ marginBottom: '30px' }}>
        Cualquier modificación podrá ser consultada a través del Portal o
        cualquier otro medio electrónico y la última versión estará indicada en
        la parte inferior donde se indicará la fecha de su actualización.
      </p>
    </div>
  )
}

export default NoticePrivacity
