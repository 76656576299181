import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  host: 'http://localhost:3000/',
  defaultLanguage: 'en-US'
}

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.defaultLanguage = action.payload
    },
    setHost: (state, action) => {
      state.host = action.payload
    },
  },
})

export const { setLanguage, setHost } = languageSlice.actions

export default languageSlice.reducer