import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { getCookie, setCookie } from './utils/cookie'
import { store } from './store/store'
import { Provider } from 'react-redux'
import './index.css'
import App from './App'

import en from './lang/en.json'
import es from './lang/es.json'

const languages = {
  'en-US': en,
  'es-ES': es,
};

const defaultLanguage = 'en-US'
const cookieName = 'lang'

const getLanguageByHost = () => {
  const host = window.location.host
  if (host.includes('vinneren.com.mx')) {
    return 'es-ES'
  } else if (host === 'www.vinneren.com' || host === 'vinneren.com' || host.includes('localhost')) {
    return 'en-US'
  }
  return null;
}

const getLanguage = () => {
  const cookieLanguage = getCookie(cookieName)
  if (!cookieLanguage) {
    const hostLanguage = getLanguageByHost()
    if (hostLanguage) {
      setCookie(cookieName, hostLanguage)
      return hostLanguage
    }
    return defaultLanguage
  }
  return cookieLanguage
}

const language = getLanguage();
const messages = languages[language];

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <IntlProvider locale={language} messages={messages} defaultLocale={defaultLanguage}>
      <App tab="home" language={language} host={window.location.host} />
    </IntlProvider>,
  </Provider>
)
