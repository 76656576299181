import { useState } from 'react'
import { useIntl } from 'react-intl'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Label, Button } from 'reactstrap'
import {VINNEREN_BASE_API} from "../utils/contants.js"


import { useForm } from 'react-hook-form'
import { jobsObj } from '../components/Modals/Modal-staffing/jobs'
import Loader from '../components/Loader'
import plusIcon from '../img/plus-circle.png'

import './staffing.css'
import './input-file.css'

const WorkWithUs = () => {
  const intl = useIntl()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [value, SetValue] = useState('')
  const [valueFile, setValueFile] = useState('')
  const [message, setMessage] = useState(null)
  const [loader, setLoader] = useState(false)
  const [base64, setBase64] = useState('');

  const isValidUrl = url => {
    try {
      // eslint-disable-next-line no-new
      new URL(url)
      return true
    } catch (error) {
      return false
    }
  }

  const handleFileChange = (event) => {
    setValueFile(event)
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      
      reader.onload = () => {
        if (reader.result) {
          setBase64(reader.result.toString().split(",")[1]); 
        }
      };
      reader.readAsDataURL(file);
    }

  };

  const onSubmit = async data => {
    data.cvFile = base64

    setLoader(true)  
    try {
      const loginResponse = await fetch(
        `${VINNEREN_BASE_API}/auth-v1/api/auth/login`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Email: data.email, 
            Name: data.fullName, 
          }),
        }
      )
      const loginData = await loginResponse.json()
      const token = loginData?.value.token
  
      const jobApplicationResponse = await fetch(
        `${VINNEREN_BASE_API}/site-v1/api/contact/jobApplication`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify(data),
        }
      )
  
      const responseData = await jobApplicationResponse.json()
      const { hasError, message } = responseData
  
      setMessage(message)
      if (!hasError) {
        setTimeout(() => {
          setLoader(false)
          window.location.reload()
        }, 2000)
      }
    } catch (error) {
      setMessage(error.message)
      setLoader(false)
    }
  }
  

  return (
    <>
      <div className="staffing">
        <div className="staffing-content">
          <h2>
            {intl.formatMessage({
              id: 'app.pages.staffing.label.title',
            })}
          </h2>
          <h4>
            {intl.formatMessage({
              id: 'app.pages.contactUs.label.subtitle',
            })}
          </h4>
          {loader && <Loader />}
          {message && <p style={{ color: '#0F0' }}>{message}</p>}
          <form onSubmit={handleSubmit(onSubmit)}>
            {window.screen.width > 768 ? (
              <div className="form-inputs">
                <div className="form-left">
                  <Label for="position">
                    {intl.formatMessage({
                      id: 'app.pages.staffing.label.position',
                    })}
                  </Label>
                  <select
                    className="select-jobs"
                    id="position"
                    name="requiredPosition"
                    placeholder="Selecciona una opción"
                    {...register('requiredPosition', { required: true })}
                  >
                    {jobsObj.map(({ name }, i) => {
                      return (
                        <>
                          <option key={i} value={name}>
                            {name}
                          </option>
                        </>
                      )
                    })}
                  </select>
                  {errors.requiredPosition && (
                    <span style={{ color: '#F00' }}>
                      {intl.formatMessage({
                        id: 'app.pages.contactUs.label.input-required',
                      })}
                    </span>
                  )}
                  <Label for="email">
                    {intl.formatMessage({
                      id: 'app.pages.contactUs.label.email',
                    })}
                  </Label>
                  <input
                    className="form-input"
                    id="email"
                    name="email"
                    {...register('email', { required: true })}
                    placeholder={intl.formatMessage({
                      id: 'app.pages.contactUs.label.email',
                    })}
                    type="email"
                  />
                  {errors.email && (
                    <span style={{ color: '#F00' }}>
                      {intl.formatMessage({
                        id: 'app.pages.contactUs.label.input-required',
                      })}
                    </span>
                  )}
                  <Label for="linkedIn">
                    {intl.formatMessage({
                      id: 'app.pages.staffing.label.linkedIn',
                    })}
                  </Label>
                  <input
                    className="form-input"
                    id="linkedIn"
                    name="linkedIn"
                    placeholder={intl.formatMessage({
                      id: 'app.pages.staffing.label.linkedIn',
                    })}
                    type="text"
                    {...register('linkedIn', {
                      required: true,
                      validate: value =>
                        isValidUrl(value) ||
                        intl.formatMessage({
                          id: 'app.pages.staffing.label.linkedIn-required2',
                        }),
                    })}
                  />
                  {errors.linkedIn && errors.linkedIn.type === 'required' && (
                    <span style={{ color: '#F00' }}>
                      {intl.formatMessage({
                        id: 'app.pages.contactUs.label.input-required',
                      })}
                    </span>
                  )}
                  {errors.linkedIn && (
                    <span style={{ color: '#F00' }}>
                      {errors.linkedIn.message}
                    </span>
                  )}
                </div>
                <div className="form-right">
                  <Label for="name">
                    {intl.formatMessage({
                      id: 'app.pages.staffing.label.name-complete',
                    })}
                  </Label>
                  <input
                    className="form-input"
                    id="name"
                    name="fullName"
                    placeholder={intl.formatMessage({
                      id: 'app.pages.staffing.label.name-complete',
                    })}
                    type="text"
                    {...register('fullName', { required: true, minLength: 4 })}
                  />
                  {errors.fullName && errors.fullName.type === 'required' && (
                    <span style={{ color: '#F00' }}>
                      {intl.formatMessage({
                        id: 'app.pages.contactUs.label.input-required',
                      })}
                    </span>
                  )}
                  {errors.fullName && errors.fullName.type === 'minLength' && (
                    <span style={{ color: '#F00' }}>
                      {intl.formatMessage({
                        id: 'app.pages.staffing.error.minLength',
                      })}
                    </span>
                  )}
                  <Label for="telphone">
                    {intl.formatMessage({
                      id: 'app.pages.contactUs.label.phone',
                    })}
                  </Label>
                  <PhoneInput
                    international
                    name="phoneNumber"
                    id="telphone"
                    placeholder={intl.formatMessage({
                      id: 'app.pages.contactUs.label.phone',
                    })}
                    defaultCountry="MX"
                    onChange={SetValue}
                    value={value}
                    {...register('phoneNumber', {
                      required: true,
                      minLength: 5,
                    })}
                  />
                  {errors.phoneNumber &&
                    errors.phoneNumber.type === 'required' && (
                      <span style={{ color: '#F00' }}>
                        {intl.formatMessage({
                          id: 'app.pages.contactUs.label.input-required',
                        })}
                      </span>
                    )}
                  {errors.phoneNumber &&
                    errors.phoneNumber.type === 'minLength' && (
                      <span style={{ color: '#F00' }}>
                        {intl.formatMessage({
                          id: 'app.pages.staffing.error.phone',
                        })}
                      </span>
                    )}
                  <div className="container-input">
                    <input
                      id="cvFile"
                      className="inputfile inputfile-1"
                      name="cvFile"
                      type="file"
                      {...register('cvFile')}
                      onChangeCapture={handleFileChange}
                    />
                    <label htmlFor="cvFile">
                      {valueFile?.target?.files[0]?.name || (
                        <div className="btn-plus">
                          <span className="iborrainputfile">
                            {intl.formatMessage({
                              id: 'app.pages.staffing.label.select-file',
                            })}
                          </span>
                          <img src={plusIcon} alt="image" />
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div className="form-inputs">
                <div className="form-left">
                  <Label for="position">
                    {intl.formatMessage({
                      id: 'app.pages.staffing.label.position',
                    })}
                  </Label>
                  <select
                    className="select-jobs"
                    id="position"
                    name="requiredPosition"
                    placeholder="Selecciona una opción"
                    {...register('requiredPosition', { required: true })}
                  >
                    {jobsObj.map(({ name }, i) => {
                      return (
                        <>
                          <option key={i} value={name}>
                            {name}
                          </option>
                        </>
                      )
                    })}
                  </select>
                  {errors.requiredPosition && (
                    <span style={{ color: '#F00' }}>
                      {intl.formatMessage({
                        id: 'app.pages.contactUs.label.input-required',
                      })}
                    </span>
                  )}
                  <Label for="name">
                    {intl.formatMessage({
                      id: 'app.pages.staffing.label.name-complete',
                    })}
                  </Label>
                  <input
                    className="form-input"
                    id="name"
                    name="fullName"
                    placeholder={intl.formatMessage({
                      id: 'app.pages.staffing.label.name-complete',
                    })}
                    type="text"
                    {...register('fullName', { required: true, minLength: 4 })}
                  />
                  {errors.fullName && errors.fullName.type === 'required' && (
                    <span style={{ color: '#F00' }}>
                      {intl.formatMessage({
                        id: 'app.pages.contactUs.label.input-required',
                      })}
                    </span>
                  )}
                  {errors.fullName && errors.fullName.type === 'minLength' && (
                    <span style={{ color: '#F00' }}>
                      {intl.formatMessage({
                        id: 'app.pages.staffing.error.minLength',
                      })}
                    </span>
                  )}
                  <Label for="email">
                    {intl.formatMessage({
                      id: 'app.pages.contactUs.label.email',
                    })}
                  </Label>
                  <input
                    className="form-input"
                    id="email"
                    name="email"
                    {...register('email', { required: true })}
                    placeholder={intl.formatMessage({
                      id: 'app.pages.contactUs.label.email',
                    })}
                    type="email"
                  />
                  {errors.email && (
                    <span style={{ color: '#F00' }}>
                      {intl.formatMessage({
                        id: 'app.pages.contactUs.label.input-required',
                      })}
                    </span>
                  )}
                </div>
                <div className="form-right">
                  <Label for="telphone">
                    {intl.formatMessage({
                      id: 'app.pages.contactUs.label.phone',
                    })}
                  </Label>
                  <PhoneInput
                    international
                    name="phoneNumber"
                    id="telphone"
                    placeholder={intl.formatMessage({
                      id: 'app.pages.contactUs.label.phone',
                    })}
                    defaultCountry="MX"
                    onChange={SetValue}
                    value={value}
                    {...register('phoneNumber', {
                      required: true,
                      minLength: 5,
                    })}
                  />
                  {errors.phoneNumber &&
                    errors.phoneNumber.type === 'required' && (
                      <span style={{ color: '#F00' }}>
                        {intl.formatMessage({
                          id: 'app.pages.contactUs.label.input-required',
                        })}
                      </span>
                    )}
                  {errors.phoneNumber &&
                    errors.phoneNumber.type === 'minLength' && (
                      <span style={{ color: '#F00' }}>
                        {intl.formatMessage({
                          id: 'app.pages.staffing.error.phone',
                        })}
                      </span>
                    )}
                  <Label for="linkedIn">
                    {intl.formatMessage({
                      id: 'app.pages.staffing.label.linkedIn',
                    })}
                  </Label>
                  <input
                    className="form-input"
                    id="linkedIn"
                    name="linkedIn"
                    placeholder={intl.formatMessage({
                      id: 'app.pages.staffing.label.linkedIn',
                    })}
                    type="text"
                    {...register('linkedIn', {
                      required: true,
                      validate: value =>
                        isValidUrl(value) ||
                        intl.formatMessage({
                          id: 'app.pages.staffing.label.linkedIn-required2',
                        }),
                    })}
                  />
                  {errors.linkedIn && errors.linkedIn.type === 'required' && (
                    <span style={{ color: '#F00' }}>
                      {intl.formatMessage({
                        id: 'app.pages.contactUs.label.input-required',
                      })}
                    </span>
                  )}
                  {errors.linkedIn && (
                    <span style={{ color: '#F00' }}>
                      {errors.linkedIn.message}
                    </span>
                  )}
                  <div className="container-input">
                    <input
                      id="cvFile"
                      className="inputfile inputfile-1"
                      name="cvFile"
                      type="file"
                      {...register('cvFile')}
                      onChangeCapture={handleFileChange}
                    />
                    <label htmlFor="cvFile">
                      {valueFile?.target?.files[0]?.name || (
                        <div className="btn-plus">
                          <span className="iborrainputfile">
                            {intl.formatMessage({
                              id: 'app.pages.staffing.label.select-file',
                            })}
                          </span>
                          <img src={plusIcon} alt="image" />
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="area-text">
              <Label for="exampleText">
                {intl.formatMessage({
                  id: 'app.pages.staffing.label.more',
                })}
              </Label>
              <textarea
                className="form-input"
                id="exampleText"
                name="moreAboutYou"
                placeholder={intl.formatMessage({
                  id: 'app.pages.staffing.label.more-of-you',
                })}
                {...register('moreAboutYou', { required: true })}
              />
              {/* errors.message && <p style={styles}>{errors.message}</p> */}
            </div>
            <Button color="primary" id="btn-send" disabled={loader}>
              {intl.formatMessage({
                id: 'app.pages.staffing.label.apply',
              })}
            </Button>
          </form>
          {/* {loading && <Loader />}
          {response && (
            <Message
              msg={intl.formatMessage({
                id: 'app.pages.contactUs.label.send-message',
              })}
              bgColor="#198754"
            />
          )} */}
        </div>
      </div>
    </>
  )
}

export default WorkWithUs
