export const jobsObj = [
  {
    name: 'Desarrollador Front End',
    image: 'FrontEnd.png',
    FuncionesConocimientos:
      'Este candidato tiene la capacidad de trabajar con un equipo técnico altamente energizado. Tiene una sólida comprensión de la gestión de dependencias de código, análisis de casos de prueba y configuración de contenedores, registro y análisis.',
    SoftSkills: [
      'Trabajo en equipo',
      'Proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto',
      'alto sentido de resultados',
    ],
    TechSkills: [
      'Javascript, React',
      'Typescript',
      'CSS',
      'SASS',
      'NodeJS',
      'ES6',
      'HMTL 5',
      'GIT',
      'Jquery',
      'Booststrap',
      'ES6',
      'Alto conocimiento en eCommerce y construcción de sistemas empresariales.',
    ],
  },
  {
    name: 'Desarrollador Back End',
    image: 'BackEnd.png',
    FuncionesConocimientos:
      'Este candidato tiene la capacidad de trabajar con un equipo técnico altamente energizado. Tiene una sólida comprensión de la gestión de dependencias de código, el análisis de casos de prueba y la configuración de contenedores, así como del registro y análisis.',
    SoftSkills: [
      'Trabajo en equipo, proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto',
      'alto sentido de resultados',
    ],
    TechSkills: [
      'java',
      'core net',
      '.Net',
      'python 3.6',
      'C#',
      '.Net 4.5 o superior',
      '.Net Core 3.1 o superior',
      'spring boot',
      'spring batch',
      'spring security',
      'JPA',
      'sql server',
      'lambdas AWS',
      'S3',
      'api gateway',
      'cloudwatch',
      'Lambda',
      'Triggers',
      'Funciones',
      'Manejo de Java 1.8 o superior',
      'conocimiento de paradigma de programación en microservicios',
      'Python',
      'dockers (contenedores, pods, clusters, uso de entornos serverless, conocimientos de CI/CD)',
      'desarrolló en ambientes cloud (GCP, AWS, Azure, etc)',
      'Alto conocimiento en eCommerce',
    ],
  },
  {
    name: 'Project Manager',
    image: 'PMO.png',
    FuncionesConocimientos:
      'En otras palabras, este perfil coordina el trabajo del equipo para cumplir con los objetivos. Tiene la capacidad de planificación, organización, coordinación y control del trabajo. Posee un amplio conocimiento en retail, eCommerce y omnicanalidad.',
    SoftSkills: [
      'Trabajo en equipo',
      'Liderazgo, proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto',
      'alto sentido de resultados',
    ],
    TechSkills: ['Jira', 'Excel avanzado', 'Proyect', 'PPT'],
  },
  {
    name: 'Product Manager',
    image: 'ProductManager.png',
    FuncionesConocimientos:
      'El rol de Product Manager tiene responsabilidades tanto de entrada como de salida. Las responsabilidades entrantes consisten en trabajar con el equipo de tecnología para ofrecer nuevas herramientas y aplicaciones de análisis en la nube. Las responsabilidades salientes incluyen reunirse con clientes, prospectos (empresas y PYMES) y analistas de la industria para analizar los requisitos del producto',
    SoftSkills: [
      'Trabajo en equipo',
      'Liderazgo, proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto',
      'alto sentido de resultados',
    ],
    TechSkills: ['Jira', 'Excel avanzado', 'Proyect', 'PPT'],
  },
  {
    name: 'Sales Rep',
    image: 'SalesRep.png',
    FuncionesConocimientos:
      'Buscamos un profesional con experiencia en ventas, gestión de ventas y ventas de software en cuentas comerciales. Debe tener habilidades excepcionales en coaching, escritura, descubrimiento y presentación, además de adaptabilidad en un entorno dinámico. Valoramos la generación de demanda, la satisfacción del cliente y la colaboración. Se requiere una licenciatura.',
    SoftSkills: [
      'Trabajo en equipo',
      'Liderazgo, proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto',
      'alto sentido de resultados',
      'apasionado en ventas',
      'negociación y planteamiento de Fases de trabajo',
    ],
    TechSkills: [],
  },
  {
    name: 'Head Técnico',
    image: 'QA.png',
    FuncionesConocimientos:
      'El rol de Head Técnico de Células tiene responsabilidades técnicas, orientación y promoción de mejores prácticas tecnológicas, así como la provisión de soluciones de clase mundial a los clientes donde esté instalada una célula de vinnenen junto a los clientes.',
    SoftSkills: [
      'Trabajo en equipo',
      'Liderazgo',
      'proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto, alto sentido de resultados',
    ],
    TechSkills: [
      'Javascript',
      'React',
      'Typescript',
      'CSS',
      'SASS',
      'NodeJS',
      'ES6',
      'HMTL 5',
      'GIT',
      'Jquery',
      'Booststrap',
      'ES6',
    ],
  },
  {
    name: 'Quality Assurance',
    image: 'QA.png',
    FuncionesConocimientos:
      'Diseño y creación de herramientas de software que permitan probar las funcionalidades y certificar la calidad de los productos generados por el área de desarrollo de la empresa.',
    SoftSkills: [
      'Trabajo en equipo',
      'proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto',
      'alto sentido de resultados',
    ],
    TechSkills: [],
  },
  {
    name: 'Scrum Master',
    image: 'ScrumMaster.png',
    FuncionesConocimientos:
      'Es responsabilidad del Administrador de Proyectos asegurar que el alcance del trabajo se realice con calidad, dentro del presupuesto y a tiempo, para que el cliente quede satisfecho. Su responsabilidad principal es proporcionar liderazgo en la planificación, organización y control del esfuerzo del trabajo para lograr el objetivo establecido.',

    SoftSkills: [
      'Trabajo en equipo',
      'proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto, alto sentido de resultados',
    ],
    TechSkills: [],
  },
  {
    name: 'Monitoring',
    image: 'Monitoring.png',
    FuncionesConocimientos:
      'Verificar el consumo de recursos en el sistema operativo. Verificar el correcto funcionamiento de las soluciones implementadas. Analizar y documentar los hallazgos encontrados. Ejecución de consultas en la base de datos. Compartir con el equipo de desarrollo el análisis realizado en caso de no poder resolver el problema.',
    SoftSkills: [
      'Trabajo en equipo',
      'proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto',
      'alto sentido de resultados',
    ],
    TechSkills: [],
  },
  {
    name: 'Full Stack',
    image: 'PMO.png',
    FuncionesConocimientos:
      'Estamos buscando un Desarrollador Full Stack para trabajar en proyectos internacionales. Tiene la capacidad de influir en el recorrido digital de cada usuario, desde la configuración de plataformas, implementaciones e integraciones, hasta la entrega de una experiencia excepcional al consumidor. Trabaja junto con todo el equipo de marketing para mejorar el rendimiento digital, mejorando aún más la entrega del recorrido del usuario.',
    SoftSkills: [
      'Trabajo en equipo',
      'proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto, alto sentido de resultados',
    ],
    TechSkills: [],
  },
  {
    name: 'Diseñador UX Senior',
    image: 'UXSenior.png',
    FuncionesConocimientos:
      'Nuestros equipos de UX aspiran a crear productos que la gente ame: herramientas que hagan que el día de un vendedor sea más productivo, que la campaña de un vendedor sea más impactante o que la aplicación de un desarrollador tenga más funcionalidades. ',
    SoftSkills: [
      'Trabajo en equipo',
      'proactividad',
      'buena organización y de trabajo bajo presión',
      'excelente comunicación',
      'capacidades analíticas y de resolución de problemas',
      'paciencia y perseverancia para resolver problemas',
      'capacidad de gestión de conflicto',
      'alto sentido de resultados',
    ],
    TechSkills: [],
  },

  /* {
    name: 'Becarios',
    image: 'PMO.png',
    FuncionesConocimientos:
      'Este rol brinda la oportunidad de trabajar con un equipo técnico de alto rendimiento. Podrá forjarse como un excelente ingeniero, entendiendo todas las fases del ciclo de vida del software. Forma parte de un equipo dinámico. Si has sido un tecnólogo de vanguardia y te apasiona mejorar la productividad de los desarrolladores, ¡entonces este es el equipo adecuado para ti!',
    SoftSkills: [],
    TechSkills: [],
  }, */
  
  {
    name: 'DigiOps',
    image: 'PMO.png',
    FuncionesConocimientos:
      'El desarrollo del puesto ayudará a las demás áreas del equipo y al cliente a resolver temas relacionados con la funcionalidad de los ambientes.',
    SoftSkills: [
      'Trabajo en equipo',
      'Multitask (trabajo con diferentes clientes al mismo tiempo)',
      'Razonamiento Lógico',
      'Autodidacta',
      'Customer Service',
      'Customer Experience',
      'Buenas prácticas sobre contenido de producto',
    ],
    TechSkills: [
      'Conocimiento de la plataforma Vtex (preferente)',
      'Conocimiento de ecommerce',
      'Conocimiento en Google Analytics y Tag Manager',
      'Excel básico',
      'Conocimiento básico de SEO',
    ],
  },
]
